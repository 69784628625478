<template>
  <div class="prose leading-9 p-5">
    <h1 class="text-lg font-bold" id="terms-of-service-and-privacy-policy">Terms of Service and Privacy Policy</h1>
    <h2 id="terms-of-service">Terms of Service</h2>
    <p><strong>Effective Date:</strong> 1st November 2024</p>
    <h3 id="1-acceptance-of-terms">1. Acceptance of Terms</h3>
    <p>By accessing or using Triese Email Reader (&quot;the App&quot;), you agree to be bound by these Terms of Service and our Privacy Policy. If you do not agree with any part of these terms, you may not use the App.</p>
    <h3 id="2-description-of-service">2. Description of Service</h3>
    <p>The App provides a service to clean up your mailbox by unsubscribing you from mailing lists in bulk. Users can connect their Gmail accounts using Google Single Sign-On (SSO). The service is provided &quot;as is&quot; and &quot;as available.&quot;</p>
    <h3 id="3-eligibility">3. Eligibility</h3>
    <p>You must be at least 18 years old and capable of entering into a binding agreement to use the App.</p>
    <h3 id="4-account-access">4. Account Access</h3>
    <p>You are responsible for safeguarding your Google account credentials and for any activities that occur through your account. The App does not store or access your password but relies on Google&#39;s secure SSO.</p>
    <h3 id="5-acceptable-use">5. Acceptable Use</h3>
    <p>You agree not to:</p>
    <ul>
      <li>Use the App for any illegal or unauthorized purpose.</li>
      <li>Attempt to disrupt or interfere with the App’s functionality.</li>
      <li>Access another user’s account without authorization.</li>
    </ul>
    <h3 id="6-subscription-and-fees">6. Subscription and Fees</h3>
    <p>The App may offer both free and paid plans. Details about pricing, subscription periods, and payment terms will be provided at the time of subscription. Fees are non-refundable except as required by law.</p>
    <h3 id="7-limitation-of-liability">7. Limitation of Liability</h3>
    <p>The App is provided &quot;as is,&quot; and we make no guarantees of its performance. To the maximum extent permitted by law, [Company Name] is not liable for any direct, indirect, incidental, or consequential damages arising from your use of the App.</p>
    <h3 id="8-termination">8. Termination</h3>
    <p>We reserve the right to suspend or terminate your access to the App at any time for violations of these terms or other lawful reasons.</p>
    <h3 id="9-changes-to-terms">9. Changes to Terms</h3>
    <p>We may update these Terms of Service from time to time. Any changes will be posted, and your continued use of the App signifies acceptance of the updated terms.</p>
    <p>For any questions or concerns, contact us at hello@trieste.io.</p>
  </div>
</template>

<script>



export default {
  name: 'TermsOfService'
}
</script>

<style scoped>
</style>
